<template>
<div class="custom">
  <b-container class="py-5">
    <h2>カスタマイズのご要望</h2>
    <p>要望に合わせてバーチャル名刺背景ジェネレーターをカスタマイズすることが可能です。<br>
      自社のロゴと背景画像が最初から組み込まれたオリジナルデザインにしたい。<br>
      入力項目を減らしたり増やしたりしたい。<br>
      別途オリジナルのジェネレーターを作成したい。<br>
      などのご相談はぜひキッズプレートまでお問い合わせください。</p>
    <b-button block variant="secondary" size="lg" class="my-5" href="https://www.kidsplates.jp/#contact" target="_blank" @click="this.$ga.event('Button', 'click', 'Goto Contact Form')">お問い合わせ</b-button>

    <h3>カスタマイズ事例</h3>
    <b-row class="case-logo">
      <b-col cols="4" offset="4">
        <div class="case-logo-item img-thumbnail">
          <img style="width: 70%;" src="@/assets/images/boston_logo.png" alt="">
        </div>
      </b-col>
    </b-row>

    <h4>ボストン・サイエンティフィックジャパン株式会社さま</h4>
    <b-row class="mt-5">
      <b-col cols="4">
        <img class="border" src="@/assets/images/custom/custom_boston_01.png" alt="">
      </b-col>
      <b-col cols="7">
        <h5>約1,000名の社員に統一規格のバーチャル背景を</h5>
        <p>全社で統一された規格の背景画像を利用し、オンラインでの企業活動におけるブランディングを図った一例です。</p>
        <p>ロゴ・背景画像・使用色・フォントに至るまでブランディングポリシーに則り設計されました。</p>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="4">
        <img class="border" src="@/assets/images/custom/custom_boston_02.png" alt="">
      </b-col>
      <b-col cols="7">
        <h5>個人情報をWEB上に残さないセキュアな仕様</h5>
        <p>従業員はボストン・サイエンティフィック専用の「カスタムジェネレーターサイト」へアクセスし、ID/PASSを入力して利用を始めます。</p>
        <p>名刺画像作成のために入力される個人情報は保存、送信されないため、安心してご利用いただけます。</p>
      </b-col>
    </b-row>

    <h5 class="text-center mt-5">カスタマイズ項目</h5>
    <b-row class="mt-3">
      <b-col cols="4" offset="2">
        <div class="case-custom-item">指定背景画像、ロゴ選択機能</div>
      </b-col>
      <b-col cols="4">
        <div class="case-custom-item">指定背景色・文字色選択</div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="4" offset="2">
        <div class="case-custom-item">指定フォント追加</div>
      </b-col>
      <b-col cols="4">
        <div class="case-custom-item">BASIC認証によるアクセス制限</div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col cols="8" offset="2">
        <div class="case-custom-item">その他調整</div>
      </b-col>
    </b-row>

    <h3>カスタマイズFAQ</h3>
    <div class="mt-3 faq-question">
      <div class="d-flex">
        <div class="icon">Q</div>
        <div class="content">カスタマイズとはどういうものですか?</div>
      </div>
    </div>
    <div class="mt-3 faq-answer">
      <div class="d-flex">
        <div class="icon">A</div>
        <div class="content">
          <img class="mb-3" src="@/assets/images/custom/custom01.jpg" alt="">
          <p>クライアント企業さま専用の画像生成ページを作成し、専用の画像作成機能を提供します(画像ではなく、専用ページを納品します)</p>
          <p>たとえば社員が自由に画像やロゴをアップロードするのではなく、会社指定のものから選ぶ仕様にして画像に統一感を持たせる、入力項目を減らして短時間で大量に作成できるようにする、などのカスタマイズが可能です。</p>
        </div>
      </div>
    </div>

    <div class="mt-3 faq-question">
      <div class="d-flex">
        <div class="icon">Q</div>
        <div class="content">専用のジェネレーターページとは何ですか?</div>
      </div>
    </div>
    <div class="mt-3 faq-answer">
      <div class="d-flex">
        <div class="icon">A</div>
        <div class="content">
          <p>ID/PASS(BASIC認証)を入力しなければアクセスできないジェネレーターページを作成し、ご提供します。</p>
        </div>
      </div>
    </div>

    <div class="mt-3 faq-question">
      <div class="d-flex">
        <div class="icon">Q</div>
        <div class="content">ページに入力したデータはどこかに送信、保存されますか?</div>
      </div>
    </div>
    <div class="mt-3 faq-answer">
      <div class="d-flex">
        <div class="icon">A</div>
        <div class="content">
          <p>利用者のPC上でデータを生成する仕様となっており、入力されたデータは弊社へ送信、保存されておりません。</p>
        </div>
      </div>
    </div>

    <h3>カスタマイズ 内容と費用</h3>
    <p>詳細はご相談ください</p>

    <b-button block variant="secondary" size="lg" class="my-5" href="https://www.kidsplates.jp/#contact" target="_blank" @click="this.$ga.event('Button', 'click', 'Goto Contact Form')">お問い合わせ</b-button>

  </b-container>
  <Footer />
</div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Footer,
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';

.custom {
  h3 {
    @extend .h5;
    @extend .text-primary;
    @extend .border-bottom;
    @extend .border-primary;
    @extend .py-2;
    @extend .mt-5;
  }

  h4 {
    @extend .h5;
    @extend .text-black-50;
    @extend .border-left;
    @extend .border-primary;
    @extend .pl-3;
    @extend .mt-5;
    font-weight: bold;
  }

  h5 {
    @extend .h3;
    margin-bottom: 0.5em;
  }

  .case {
    &-logo {
      &-item {
        display: flex;
        height: 100%;
        padding: 1.4vw 2.8vw;
        justify-content: center;
        align-items: center;
      }
    }

    &-custom {
      &-item {
        display: flex;
        height: 100%;
        padding: 1vw 2vw;
        justify-content: center;
        align-items: center;
        @extend .border;
        @extend .bg-light;
        @extend .rounded;
      }
    }
  }

  .faq {
    &-question {
      font-size: 1.4rem;
      @extend .text-black-50;

      .icon {
        display: inline-flex;
        width: 2em;
        height: 2em;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 2rem;
        @extend .bg-primary;
        @extend .mr-3;
      }

      .content {
        flex: 1;
        padding: 1rem 0;
      }
    }

    &-answer {
      .icon {
        display: inline-flex;
        width: 2em;
        height: 2em;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-size: 2rem;
        @extend .bg-secondary;
        @extend .mr-3;
      }

      .content {
        flex: 1;
        padding: 1rem 0;
      }
    }
  }
}
</style>
