<template>
  <div class="footer pb-5" v-if="!setting.hideProduceBy">
    <b-container fluid>
      <b-row class="px-3">
        <b-col align-self="end">
          <a href="https://kidsplates.jp/"><img style="width: 160px;" src="@/assets/images/kidsplates_logo.png" alt=""></a>
        </b-col>
        <b-col align-self="end" class="text-right">
          &copy; 2020 Kidsplates.
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
// import { saveAs } from 'file-saver'

export default {
  computed: {
    setting() {
      return this.$store.state.setting
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
}
</style>
